import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {CommonModule} from "@angular/common"

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PrizePageComponent } from './components/prize-page/prize-page.component';
import { HelloPageComponent } from './components/hello-page/hello-page.component';
import { FileUploadModule } from 'ng2-file-upload';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ShareTitleComponent } from './components/share-title/share-title.component';
import { SafeHTMLPipe } from './pipes/safe-html.pipe';

@NgModule({
  declarations: [
    AppComponent,
    PrizePageComponent,
    HelloPageComponent,
    ShareTitleComponent,
    SafeHTMLPipe
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,    
    FormsModule,
    ReactiveFormsModule,
    EditorModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
