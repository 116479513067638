<div>
  <img src="{{ background }}" class="video" />
  <div id="wrapper">
    <div id="content">
      <div class="container h-100">
        <div class="row h-100">
          <div class="col-12 text-center m-auto">
            <!-- <h1 class="linear-wipe">{{title}}</h1> -->
            <share-title></share-title>
            <h2 class="shadow-text">{{ currentAwardName }} ({{ avaiableCount }})</h2>
          </div>

          <div class="col-12">
            <div class="spinner">
              <div class="spinner__body"></div>
              <button class="spinner__start-button" (click)="toggleRoll()" autofocus *ngIf="avaiableCount !== 0">
                {{ !isToggle ? 'Quay' : 'Dừng' }}
              </button>
              <button *ngIf="!this.data.currentAward" class="spinner__input" (click)="download()">⏬</button>
              <button class="spinner__next" (click)="nextAward()" *ngIf="this.data.currentAward && avaiableCount === 0">
                {{ data.currentPrizeIndex + 1 === this.data.setting.awards.length ? 'Kết thúc giải' : 'Giải kế tiếp' }}
              </button>

              <div class="spinner__plate" [class.spinner__plate--spin]="isSpinning">
                <div class="spinner__item spinner__item--top">
                  {{ itemTrungGiai?.id }}
                </div>
                <div class="spinner__item spinner__item--right">{{ randomId }};</div>
                <div class="spinner__item spinner__item--bottom">
                  {{ randomId }}
                </div>
                <div class="spinner__item spinner__item--left">
                  {{ randomId }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 text-center congratulation-info">
            <h2 class="shadow-text">{{ congratulationText }}</h2>
            <h3 class="shadow-text">{{ congratulationAddress }}</h3>
          </div>
        </div>
      </div>
    </div>
    <nav id="sidebar" [class.collapsed]="collapsed">
      <div class="header translatey-25vh-above">
        <div class="expandBtn">
          <span id="sidebarCollapse"
            ><i class="fa fa-arrow-right shadow-text text-dark" [class.fa-arrow-right]="!collapsed" [class.fa-arrow-left]="collapsed" (click)="collapsed = !collapsed"></i
          ></span>
        </div>
      </div>
      <div class="overflow-y--auto translatey-25vh">
        <ul class="navigation">
          <li class="text-center" *ngFor="let item of gotchaShow">
            <div class="card mx-1 mb-2">
              <div class="card-body p-2">
                <span class="font-weight-bold">{{ item.id }}</span>
                <p class="mb-1" *ngIf="!collapsed">{{ item.name }}</p>
                <button class="w-50 btn btn-sm btn-danger mb-1" *ngIf="!collapsed" (click)="undoRoll(item.id)">Hủy</button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>
