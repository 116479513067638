<div class="overlay"></div>

<img src="{{ this.result.background }}" class="video" />
<div class="masthead">
  <div class="masthead-bg"></div>
  <div class="container h-100">
    <div class="row h-100">
      <div class="col-12 my-auto">
        <div class="masthead-content py-5 py-md-0">
          <h1 class="mt-3 linear-wipe">Quay số</h1>
          <h1 class="text-right mb-3 p-0 linear-wipe">trúng thưởng</h1>
          <div>
            <form [formGroup]="dataForm">
              <div class="form-group">
                <label class="form-control-label font-weight-bold">Hình nền</label>
                <div class="custom-file">
                  <input type="file" (change)="backgroundChange($event)" accept="image/*" class="custom-file-input" id="background" />
                  <label class="custom-file-label" for="background" id="labelBackground">Chọn Hình Ảnh</label>
                </div>
              </div>
              <div class="form-group">
                <label class="form-control-label font-weight-bold">Tệp Excel (*)</label>
                <div class="custom-file">
                  <input
                    onclick="this.value=null;"
                    type="file"
                    (change)="excelChange($event)"
                    accept="image/*"
                    class="custom-file-input"
                    id="excel"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    [ngClass]="{
                      'is-invalid': isSubmited && fControls.excelName.invalid
                    }"
                  />
                  <label class="custom-file-label" for="excel" id="labelExcel">Chọn Tệp Excel</label>
                </div>
                <div class="small text-danger" *ngIf="isSubmited && fControls.excelName.invalid">Vui lòng chọn file Excel</div>
              </div>
              <div class="form-group">
                <label class="form-control-label font-weight-bold">Tiêu đề</label>
                <div class="small text-danger" *ngIf="isSubmited && fControls.title.invalid">Vui lòng nhập tiêu đề</div>
                <editor formControlName="title" apiKey="ja7qkn5r71sokrpr7iazxpq1tdtsakdduelmc1fn6ovu3e3j" [init]="initMCE"> </editor>
              </div>

              <div class="form-group">
                <div class="custom-control custom-switch" [formGroup]="dataForm">
                  <input type="checkbox" class="custom-control-input" id="isInclude-all" formControlName="isInclude" />
                  <label class="custom-control-label font-weight-bold" for="isInclude-all"> Cho phép trùng giải </label>
                </div>
              </div>

              <div class="form-group" formArrayName="awards">
                <label class="form-control-label font-weight-bold"> Cơ cấu giải thưởng (*) </label>
                <div class="small text-danger" *ngIf="isSubmited && awards.length == 0">Vui lòng thêm ít nhất 1 giải</div>
                <table class="table">
                  <thead>
                    <tr>
                      <th class="text-center">Trùng giải</th>

                      <th>Tên giải</th>
                      <th width="100">Số lượng</th>
                      <th width="50">
                        <button class="btn btn-sm btn-primary float-right" (click)="addAward()">
                          <i class="fas fa-plus"></i>
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody *ngFor="let item of awards.controls; let i = index">
                    <tr [formGroupName]="i">
                      <td class="text-center">
                        <div class="custom-control custom-checkbox" *ngIf="fControls.isInclude.value">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="aw{{ i }}"
                            formControlName="isInclude"
                            [ngClass]="{
                              'is-invalid': isSubmited && item.controls.name.invalid
                            }"
                          />
                          <label class="custom-control-label" for="aw{{ i }}"> </label>
                        </div>
                        <div class="custom-control custom-checkbox" *ngIf="!fControls.isInclude.value">
                          <input type="checkbox" class="custom-control-input" id="awok{{ i }}" [checked]="false" disabled />
                          <label class="custom-control-label" for="awok{{ i }}"></label>
                        </div>
                      </td>
                      <td>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          formControlName="name"
                          [ngClass]="{
                            'is-invalid': isSubmited && item.controls.name.invalid
                          }"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          formControlName="count"
                          [ngClass]="{
                            'is-invalid': isSubmited && item.controls.count.invalid
                          }"
                        />
                      </td>
                      <td>
                        <button class="btn btn-sm btn-danger float-right" (click)="removeAward(i)">
                          <i class="fas fa-minus"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="form-group">
                <button (click)="submit()" class="btn btn-primary">Bắt đầu <i class="fas fa-angle-double-right"></i></button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
