import { Injectable } from '@angular/core';
import _ from 'lodash';
import { AwardModel, ExcelModel, SettingModel } from '../models';
import { PrizeDetail } from '../models/numberPanel.interface';

@Injectable({
  providedIn: 'root',
})
export class PrizeService {
  setting: SettingModel;
  maxExcelId: number;
  isSet: boolean = false;

  // Sô index của giải hiện tại
  currentPrizeIndex: number = 0;
  // Giải thưởng hiện tại
  currentAward: AwardModel;

  // Danh sách trúng giải của giải thưởng hiện tại
  gotcha: ExcelModel[] = [];
  // Danh sách trúng giải từ trước đến giờ
  prizeCollection: PrizeDetail[] = [];

  constructor() {}

  setSetting(set: SettingModel) {
    this.reset();
    this.setting = set;
    this.maxExcelId = set.excecls.length;
    this.isSet = true;
    this.currentAward = this.setting.awards[this.currentPrizeIndex];
  }

  reset() {
    this.setting = null;
    this.maxExcelId = 0;
    this.isSet = false;
    this.currentPrizeIndex = 0;
    this.gotcha = [];
    this.prizeCollection = [];
  }

  nextRoll(idTrungGiai: number): ExcelModel {
    const trungGiai = this.setting.excecls.find((x) => x.id == idTrungGiai);
    this.gotcha.push(trungGiai);
    return trungGiai;
  }

  undoRoll(idTrungGiai: number) {
    _.remove(this.gotcha, (x) => x.id === idTrungGiai);
  }

  nextAward() {
    if (!this.currentAward) return;

    if (this.currentAward.count === this.gotcha.length) {
      this.prizeCollection.push({
        gotchaList: this.gotcha,
        prizeName: this.currentAward.name,
      });
    }

    if (this.currentPrizeIndex === this.setting.awards.length - 1) {
      this.currentAward = undefined;
    } else {
      this.currentPrizeIndex++;
      this.currentAward = this.setting.awards[this.currentPrizeIndex];
      this.gotcha = [];
      console.log(this.currentPrizeIndex);
    }
  }
}
