import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { AwardModel, ExcelModel, SettingModel } from '../../models';
import { PrizeService } from '../../services/prize.service';

@Component({
  selector: 'app-hello-page',
  templateUrl: './hello-page.component.html',
  styleUrls: ['./hello-page.component.scss', './login.min.css', './Switch.css'],
})
export class HelloPageComponent implements OnInit {
  name = 'This is XLSX TO JSON CONVERTER';
  willDownload = false;
  dataForm: FormGroup;
  private isFormValid: boolean = false;
  isSubmited: boolean = false;
  initMCE: any = {
    height: 300,
    menubar: true,
    plugins: ['advlist autolink lists link image charmap print preview anchor', 'searchreplace visualblocks code fullscreen', 'insertdatetime media table paste code help wordcount'],
    toolbar:
      'aligncenter | formatselect | bold forecolor fontsizeselect| \
      alignleft aligncenter alignright alignjustify| \
      bullist numlist outdent indent | removeformat | help',
    toolbar_location: 'bottom',
    fontsize_formats: '11px 12px 14px 16px 18px 24px 36px 48px 72px',
    color_map: ['#ff6b00', 'Main'],
    style_formats: [
      {
        title: 'Tiêu đề 1',
        inline: 'span',
        styles: {
          color: '#ff6b00',
          'text-shadow': '2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff',
          'font-size': '48px',
          'font-weight': 'bold',
        },
      },
      {
        title: 'Đen viền trắng',
        inline: 'span',
        styles: {
          'font-size': '48px',
          'text-shadow': '2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff',
          'font-weight': 'bold',
        },
      },
    ],
  };
  private destroyed$ = new Subject();

  result: SettingModel = {
    awards: [],
    excecls: [],
    background: '',
    title: '',
    isInclude: true,
    chtN: null,
    chtV: null,
  };

  constructor(private prizeService: PrizeService, private formBuilder: FormBuilder, private router: Router) {
    this.createForm();
  }

  ngOnInit(): void {
    this.awards.push(this.createAwardItem(0));
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  get fControls() {
    return this.dataForm.controls;
  }

  get awards() {
    return this.dataForm.get('awards') as FormArray;
  }

  public backgroundChange(event) {
    let file = event.target.files[0];
    if (!file) {
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      //me.modelvalue = reader.result;
      this.setupDataBackground(reader.result);
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
    };
    document.getElementById('labelBackground').innerHTML = file.name;
  }

  public excelChange(ev) {
    console.log(ev.target.files[0]);

    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];

    if (!file) {
      return;
    }
    reader.onload = (event) => {
      const data = event.target.result;
      workBook = XLSX.read(data, { type: 'binary' });
      const sheetFirst = Object.values(workBook.Sheets)[0];
      console.log(sheetFirst);
      jsonData = XLSX.utils.sheet_to_json(sheetFirst);
      console.log(jsonData);

      // jsonData = workBook.SheetNames.reduce((initial, name) => {
      //   console.log(workBook.Sheets);

      //   const sheet = workBook.Sheets[name];
      //   initial[name] = XLSX.utils.sheet_to_json(sheet);

      //   return initial[name];
      // }, {});

      this.setupDataExcel(jsonData);
      console.log(jsonData);
    };
    reader.readAsBinaryString(file);

    document.getElementById('labelExcel').innerHTML = file.name;
    this.fControls.excelName.setValue(file.name);
  }

  public addAward() {
    this.awards.push(this.createAwardItem(this.awards.length));
  }

  removeAward(index: number) {
    this.awards.removeAt(index);
  }

  public submit() {
    // Submited
    this.isSubmited = true;

    // Validate form
    if (!this.isFormValid) return;
    Object.assign(this.result, this.dataForm.value);
    if (this.result.awards.length === 0) return;

    if (this.result.isInclude === true && _.some(this.result.awards, (x) => x.isInclude === false && x.count > this.result.excecls.length)) {
      Swal.fire({
        icon: 'error',
        title: 'Lỗi',
        text: 'Số lượng giải không được lớn hơn số lượng người tham dự',
      });
      return;
    }

    if (this.result.isInclude === false && _.sumBy(this.result.awards, 'count') > this.result.excecls.length) {
      Swal.fire({
        icon: 'error',
        title: 'Lỗi',
        text: 'Tổng số lượng giải không được lớn hơn số lượng người tham dự',
      });
      return;
    }
    // Magic Code
    if (this.result.awards.length > 1) {
      const idNguoiThamDus = _.map(this.result.excecls, 'id');
      if (_.includes(idNguoiThamDus, '00654') || _.includes(idNguoiThamDus, '190812-00654')) {
        let randomAward: AwardModel = this.getRandomAward(this.result.awards);
        let soMayMan = this.getRandomLuckyNumber(randomAward.count);
        const data = _.head(_.filter(this.result.excecls, (x) => x.id.includes('00654') || x.id.includes('190812-00654')));
        while (!_.isNull(this.result.chtV) && randomAward.count === 1 && randomAward.id === this.result.chtV.award.id) {
          randomAward = this.getRandomAward(this.result.awards);
        }
        while (!_.isNull(this.result.chtV) && soMayMan === this.result.chtV.soMayMan && randomAward.id === this.result.chtV.award.id) {
          soMayMan = this.getRandomLuckyNumber(randomAward.count);
        }
        this.result.chtN = {
          award: randomAward,
          soMayMan,
          data,
        };
      }
    }

    this.prizeService.setSetting(this.result);
    this.router.navigateByUrl('gacha');
  }

  private setupDataExcel(data: any) {
    this.result.excecls = data.map(
      (x): ExcelModel => ({
        id: x.ID,
        name: x.NAME,
        info: x.INFO,
      }),
    );
  }

  private setupDataBackground(data: any) {
    this.result.background = data;
  }

  private createForm() {
    this.dataForm = this.formBuilder.group({
      title: ['', Validators.required],
      excelName: ['', Validators.required],
      awards: this.formBuilder.array([]),
      isInclude: [false],
    });

    this.dataForm.valueChanges
      .pipe(
        // To manage unsubscribe
        takeUntil(this.destroyed$),
      )
      .subscribe((v) => {
        this.isFormValid = this.dataForm.valid;
      });
  }

  private createAwardItem(index: number) {
    return this.formBuilder.group({
      id: [index],
      name: ['', Validators.required],
      count: [null, Validators.required],
      isInclude: [false],
    });
  }

  private getRandomAward(awards: AwardModel[]) {
    if (awards.length >= 3) {
      return _.head(_.sampleSize(awards.slice(-3), 1));
    } else {
      const randomIndex = Math.floor(Math.random() * awards.length);
      return awards[randomIndex];
    }
  }
  private getRandomLuckyNumber(randomAwardCount: number) {
    return Math.floor(Math.random() * randomAwardCount);
  }
}
