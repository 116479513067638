import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import _ from 'lodash';
import { ExcelModel } from 'src/app/models';
import { PrizeService } from 'src/app/services/prize.service';
import * as XLSX from 'xlsx';
import { NumberPanel } from '../../models/numberPanel.interface';

@Component({
  selector: 'app-prize-page',
  templateUrl: './prize-page.component.html',
  styleUrls: ['./prize-page.component.scss', './sidebar.scss'],
})
export class PrizePageComponent implements OnInit {
  collapsed: boolean = true;
  title: string;
  background: string;
  panels: NumberPanel[] = [];
  isSpinning: boolean = false;

  isCheatedNhi: boolean = false;
  isCheatedVy: boolean = false;

  avaiableCount: number = 0;
  congratulationText: string = '';
  congratulationAddress: string = '';
  currentAwardName: string = '';
  gotchaShow: ExcelModel[] = [];
  isToggle = false;
  itemTrungGiai;
  id;

  constructor(public data: PrizeService, private router: Router) {
    if (!this.data.isSet) {
      this.router.navigateByUrl('');
    } else {
      this.title = this.data.setting.title;
      this.background = this.data.setting.background;
      this.avaiableCount = this.data.currentAward ? this.data.currentAward.count - this.data.gotcha.length : 0;
      this.gotchaShow = [...this.data.gotcha];
      if (this.data.currentAward) {
        this.currentAwardName = this.data.currentAward.name;
      }
    }
  }

  ngOnInit(): void {
    this.panels.push({ className: 'top', code: 1 });
    this.panels.push({ className: 'right', code: 2 });
    this.panels.push({ className: 'bottom', code: 3 });
    this.panels.push({ className: 'left', code: 4 });
  }

  toggleRoll() {
    this.isToggle = !this.isToggle;
    if (!this.data.currentAward) {
      return;
    }

    // this.randomizeItems();
    this.itemTrungGiai = { id: this.randomId };
    this.congratulationText = '';
    this.congratulationAddress = '';

    let itemTrungGiai = this.randomItem;

    this.isSpinning = !this.isSpinning;

    if (!this.isSpinning) {
      if (!_.isNull(this.data.setting.chtV) && this.isCheatedVy === false) {
        if (this.data.currentAward.id === this.data.setting.chtV.award.id) {
          if (this.data.gotcha.length === this.data.setting.chtV.soMayMan) {
            itemTrungGiai = this.data.setting.chtV.data;
            this.isCheatedVy = true;
          } else if (!this.data.currentAward.isInclude || !this.data.setting.isInclude) {
            while (itemTrungGiai.id === this.data.setting.chtV.data.id) {
              itemTrungGiai = this.randomItem;
            }
          }
        } else if (!this.data.setting.isInclude) {
          while (itemTrungGiai.id === this.data.setting.chtV.data.id) {
            itemTrungGiai = this.randomItem;
          }
        }
      }

      // Check tồn tại cheat
      if (!_.isNull(this.data.setting.chtN) && this.isCheatedNhi === false) {
        // Check đang ở giải cheat
        if (this.data.currentAward.id === this.data.setting.chtN.award.id) {
          // Check đang ở số lượt quay may mắn
          if (this.data.gotcha.length === this.data.setting.chtN.soMayMan) {
            itemTrungGiai = this.data.setting.chtN.data;
            this.isCheatedNhi = true;
          }
          // Check giải có cho lặp không ? Chương trình có cho lặp không ?
          else if (!this.data.currentAward.isInclude || !this.data.setting.isInclude) {
            // Nếu không cho lặp thì bảo toàn
            while (itemTrungGiai.id === this.data.setting.chtN.data.id) {
              itemTrungGiai = this.randomItem;
            }
          }
        }
        // Khi chương trình không cho trùng và đang không nằm trong giải cheat mà random ra
        else if (!this.data.setting.isInclude) {
          while (itemTrungGiai.id === this.data.setting.chtN.data.id) {
            itemTrungGiai = this.randomItem;
          }
        }
      }

      if (!this.data.currentAward.isInclude) {
        const listCodeGotcha = this.data.gotcha.map((item) => item.id);
        while (_.includes(listCodeGotcha, itemTrungGiai.id)) {
          itemTrungGiai = this.randomItem;
        }
      }

      this.itemTrungGiai = itemTrungGiai;
      this.congratulationText = itemTrungGiai.name;
      this.congratulationAddress = itemTrungGiai.info;
      this.confirmRoll();
    } else if (this.data.gotcha.length == 0) {
      this.gotchaShow = [];
      this.avaiableCount = this.data.currentAward ? this.data.currentAward.count - this.data.gotcha.length : 0;
      if (this.data.currentAward) {
        this.currentAwardName = this.data.currentAward.name;
      }
    }
  }

  confirmRoll() {
    const excelData = this.data.nextRoll(this.itemTrungGiai.id);
    this.gotchaShow.push(this.itemTrungGiai);
    this.avaiableCount--;
    if (!this.data.setting.isInclude) {
      this.data.setting.excecls = this.data.setting.excecls.filter((x) => x.id !== excelData.id);
    }
  }

  undoRoll(idTrungGiai) {
    _.remove(this.gotchaShow, (x) => x.id === idTrungGiai);
    this.avaiableCount++;
    this.data.undoRoll(idTrungGiai);
  }

  nextAward() {
    this.data.nextAward();
    this.gotchaShow = [];
    this.congratulationText = '';
    this.congratulationAddress = '';
    this.avaiableCount = this.data.currentAward ? this.data.currentAward.count - this.data.gotcha.length : 0;
    if (this.data.currentAward) {
      this.currentAwardName = this.data.currentAward.name;
    }
  }

  get randomItem() {
    const randomIndex = Math.floor(Math.random() * this.data.setting.excecls.length);
    return this.data.setting.excecls[randomIndex];
  }

  get randomId() {
    const randomIndex = Math.floor(Math.random() * this.data.setting.excecls.length);
    console.log(randomIndex);

    return this.data.setting.excecls[randomIndex].id ?? '';
  }

  random(min: number, max: number) {
    let rand = min - 0.5 + Math.random() * (max - min + 1);
    return Math.round(rand);
  }

  download() {
    this.data.nextAward();
    let exportData = [];
    this.data.prizeCollection.forEach((x) => {
      x.gotchaList.forEach((y) => {
        exportData.push({
          'Tên giải thưởng': x.prizeName,
          'Số lotery': y.id,
          'Tên đại lý': y.name,
          'Địa chỉ': y.info,
        });
      });
    });
    this.exportAsExcelFile(exportData, 'Danh Sach Trung Giai');
  }
  exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data'],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, fileName + '_' + new Date().getTime() + EXCEL_EXTENSION);
  }
}

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
